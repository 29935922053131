<nav class="navbar navbar-expand-lg navbar-light bg-light">
	<ul class="nav navbar-nav">
		<li class="nav-item">
			<button class="nav-link btn btn-light" [disabled]="paging.currentPage == 1 || disabled" (click)="onChangePage('first')"><i class="material-icons">first_page</i></button>
		</li>
		<li class="nav-item">
			<button class="nav-link btn btn-light" [disabled]="paging.currentPage == 1 || disabled" (click)="onChangePage('prev')"><i class="material-icons">chevron_left</i></button>
		</li>
	</ul>
	
	<ul class="nav navbar-nav mx-auto">
		<li class="nav-item" *ngFor="let num of paging.pagerNumRange">
			<button class="nav-link btn btn-light" [class.active]="num == paging.currentPage" [disabled]="disabled" (click)="onChangePage(num)">{{num}}</button>
			
		</li>
	</ul>
	
	<ul class="nav navbar-nav">
		<li class="nav-item">
			<button class="nav-link btn btn-light" [disabled]="paging.currentPage == paging.lastPage || disabled" (click)="onChangePage('next')"><i class="material-icons">chevron_right</i></button>
		</li>
		<li class="nav-item">
			<button class="nav-link btn btn-light" [disabled]="paging.currentPage == paging.lastPage || disabled" (click)="onChangePage('last')"><i class="material-icons">last_page</i></button>
		</li>
	</ul>
</nav>