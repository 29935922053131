<div class="home">
	<div class="fix-width">
		<div class="row banner-text">
			<div class="col-lg-6 m-t-20">
				<h1 style="color: #bb1111">
					<i class="material-icons" style="font-size: inherit">event</i> <span>Wnioski:<br>Naliczenie PREMII punktowych</span></h1>
			</div>
			<div class="col-lg-6">
				<div [ngStyle]="{ 'background-image': 'url(angular/assets/images/discs-images/' + imageNumber + '.jpg)' }"
					 style="background-position: center center; height: 165px; background-size: cover; border-radius: 0.4167rem; margin-bottom: 10px;"></div>
			</div>
		</div>
	</div>
</div>

<div class="card mt-3 mb-2">
	<div class="card-body">
		<div>
			<div class="row mb-3">
				<div class="col-md-12">
					<div class="row">
						<div class="input-group input-group-sm col-md-3">
							<div class="input-group-prepend">
								<div class="input-group-prepend">
									<label class="input-group-text" for="selecttYear">Rok:</label>
								</div>
								<select id="selectYear" class="custom-select form-control-sm" [(ngModel)]="filterYear" (change)="onChangeQueryFilter('Year')">
									<option value="0">- wszystkie -</option>
									<option *ngFor="let year of statYears" [ngValue]="year">{{year}}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="table-responsive" *ngIf="applications">
			<table class="table table-sm table-bordered table-striped">
				<thead>
					<th></th>
					<th class="sort" (click)="onChangeOrderFilter('DisciplineName')">
						Dyscyplina
						<i *ngIf="sort === 'DisciplineName ASC'" class="material-icons"> keyboard_arrow_up</i>
						<i *ngIf="sort === 'DisciplineName DESC'" class="material-icons"> keyboard_arrow_down</i>
					</th>
					
					<th class="sort" (click)="onChangeOrderFilter('PlayerLastName')">
						Nazwisko imię
						<i *ngIf="sort === 'PlayerLastName ASC'" class="material-icons"> keyboard_arrow_up</i>
						<i *ngIf="sort === 'PlayerLastName DESC'" class="material-icons"> keyboard_arrow_down</i>
					</th>
					
					<th class="sort" (click)="onChangeOrderFilter('ClubName')" style="min-width: 200px;">
						Klub
						<i *ngIf="sort === 'ClubName ASC'" class="material-icons"> keyboard_arrow_up</i>
						<i *ngIf="sort === 'ClubName DESC'" class="material-icons"> keyboard_arrow_down</i>
					</th>
					
					<th class="sort" (click)="onChangeOrderFilter('EventName')">
						Wydarzenie
						<i *ngIf="sort === 'EventName ASC'" class="material-icons"> keyboard_arrow_up</i>
						<i *ngIf="sort === 'EventName DESC'" class="material-icons"> keyboard_arrow_down</i>
					</th>
					
					<th class="sort" (click)="onChangeOrderFilter('LiczbaPunktow')">
						Liczba punktów
						<i *ngIf="sort === 'LiczbaPunktow ASC'" class="material-icons"> keyboard_arrow_up</i>
						<i *ngIf="sort === 'LiczbaPunktow DESC'" class="material-icons"> keyboard_arrow_down</i>
					</th>
					
					<th class="sort" (click)="onChangeOrderFilter('Status')">
						Status
						<i *ngIf="sort === 'Status ASC'" class="material-icons"> keyboard_arrow_up</i>
						<i *ngIf="sort === 'Status DESC'" class="material-icons"> keyboard_arrow_down</i>
					</th>
					
					<th class="sort" (click)="onChangeOrderFilter('DataZlozenia')">
						Data złożenia wniosku
						<i *ngIf="sort === 'DataZlozenia ASC'" class="material-icons"> keyboard_arrow_up</i>
						<i *ngIf="sort === 'DataZlozenia DESC'" class="material-icons"> keyboard_arrow_down</i>
					</th>
					
					<th class="sort" (click)="onChangeOrderFilter('DataDecyzji')">
						Data decyzji
						<i *ngIf="sort === 'DataDecyzji ASC'" class="material-icons"> keyboard_arrow_up</i>
						<i *ngIf="sort === 'DataDecyzji DESC'" class="material-icons"> keyboard_arrow_down</i>
					</th>
					
					<th>Uzasadnienie decyzji</th>
				</thead>
				<tbody>
					<tr id="filters">
						<td></td>
						<td>
							<select id="selectDiscipline" class="custom-select form-control-sm"	[(ngModel)]="filterDisciplineId" (change)="onChangeQueryFilter('disciplineId')">
								<option value="0">- wszystkie -</option>
								<option *ngFor="let discipline of disciplines" [ngValue]="discipline.id">{{discipline.name}}</option>
							</select>
						</td>
						<td><input id="selectLastname" class="form-control" type="text" [(ngModel)]="filterLastName" (input)="onChangeQueryFilter('lastname')"></td>
						<td colspan="3"></td>
						<td>
							<select id="selectStatus" class="custom-select form-control-sm"	[(ngModel)]="filterStatus" (change)="onChangeQueryFilter('status')">
								<option value="">- wszystkie -</option>
								<option value="Wysłany">wysłany</option>
								<option value="Zatwierdzony">zatwierdzony</option>
								<option value="Odrzucony">odrzucony</option>
							</select>
						</td>
						<td colspan="3"></td>
					</tr>
					
					<tr *ngFor=" let app of applications; index as i">
						<td>{{ app.lp }}</td>
						<td>{{ app.disciplineName }}</td>
						<td><a routerLink="/zawodnik/{{ app.playerId }}/{{ app.playerUrl }}" target="_blank">{{ app.playerLastName }} {{ app.playerFirstName }}</a></td>
						<td><a routerLink="/klub/{{ app.clubId }}/{{ app.clubUrl }}" target="_blank">{{ app.clubName }}</a></td>
						<td><a routerLink="/wydarzenie/{{ app.eventId }}/{{ app.eventUrl }}" target="_blank">{{ app.eventName }}</a></td>
						
						<td>{{ app.liczbaPunktow }}</td>
						
						<td>{{ app.status }}</td>
						<td>{{ app.dataZlozenia }}</td>
						<td>{{ app.dataDecyzji }}</td>
						
						<td><div [innerText]="app.uzasadnienieDecyzji | lineBreak"></div></td>
					</tr>
				</tbody>
			</table>
		</div>
		<utils-pager-navbar *ngIf="paging && applications && totalNumberOfRows > paging.pageSize" [paging]="paging" (changePage)="onChangePage($event)"></utils-pager-navbar>
	</div>
</div>