import { Component, OnDestroy } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';

@Component({
	templateUrl: './rodo-modal.component.html'
})
export class RodoModalComponent implements OnDestroy {
	constructor(private cookieService: CookieService, public activeModal: NgbActiveModal) {}
	
	
	ngOnDestroy(): void {
		this.cookieService.set('rodoModalClosed', 'true', 10000);
	}
}
