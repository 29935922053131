import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';

import { SessionService } from 'shared/services/session.service';

@Injectable({
  	providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(private sessionsService: SessionService, private router: Router) {}

	async canActivate(): Promise<boolean> {
		return await this.isAdmin();
	}

	async canActivateChild(): Promise<boolean> {
		return await this.isAdmin();
	}

	async canLoad(): Promise<boolean> {
		return await this.isAdmin();
	}

	async isAdmin(): Promise<boolean> {
		let rights = await this.sessionsService.getRights();
		
		if (rights && (rights.canAdmin || rights.canAdminDisc)) {
			return true;
		} else {
			this.router.navigate(['logowanie']);
			return false;
		}
	}
}
