export class User {
    id?: number;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    userClass: string;
    isActive: boolean;
    notifyScoredPointsNote: boolean;
    canEditResultsImportDate: boolean;
    
    userClassViewName?: string;
    permissions?: string;
    deleting?: boolean;
	
    public static generateUserClassViewName(user: User, disciplines: any[]): string {
        let userClassViewName = '';
		
        if (user.userClass === 'SYS_ADMIN') {
            userClassViewName = 'Administrator systemowy';
        } else if (user.userClass === 'DISC_ADMIN') {
            if (user.permissions) {
                let userDisciplinesArray = this.getUserAdminDisciplines(user);
    			
                let userDisciplinesNames = '';
                userDisciplinesArray.forEach(disciplineId => {
                    userDisciplinesNames = userDisciplinesNames + (disciplines.find(disc => disc.id == disciplineId).name)
                                           + (userDisciplinesArray.length > 1 && userDisciplinesArray.indexOf(disciplineId) < userDisciplinesArray.length - 1 ? ', ' : '');
                });
                
                userClassViewName = (userDisciplinesArray.length > 1 ? 'Adminstrator dyscyplin: ' : 'Administrator dyscypliny - ') + userDisciplinesNames;
            }
        }
        return userClassViewName;
    }
	
    public static getUserAdminDisciplines(user: User): number[] {
        let strAdminDisciplines = user.permissions.split(',');
        let arrayAdminDisciplines: number[] = [];
		
        strAdminDisciplines.forEach(disc => {
            arrayAdminDisciplines.push(Number(disc));
        })
		
        return arrayAdminDisciplines;
    }
	
    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.phone = '';
        this.email = '';
        this.isActive = false;
        this.userClass = '';
        this.permissions = '';
        this.deleting = false;
    }
}