import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { User } from 'shared/model/user';
import { Rights } from 'shared/model/rights';

import { SessionService } from './shared/services/session.service';
import { StatisticsService } from 'shared/services/statistics.service';
import { MiscService } from 'shared/services/misc.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
    constructor(private router: Router, private sessionService: SessionService, private statisticsService: StatisticsService, private miscService: MiscService) {
        this.routeSubscription = router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
                this.currentUrl = e.url;
                this.showStatisticsBubbles =    this.currentUrl === '/' ||
                                                this.currentUrl === '/logowanie' ||
                                                this.currentUrl === '/resetowanie-hasla' ||
                                                this.currentUrl.indexOf('/admin') >= 0 ||
                                                this.currentUrl.indexOf('/nowe-haslo') >= 0 ||
                                                this.currentUrl.indexOf('/aktywacja-konta') >= 0 ? false : true;
			}
		})
    }
	
    private routeSubscription: Subscription;
    private sessionDataSubscription: Subscription;
	
    public loggedUser: User;
    public rights: Rights;
    public currentUrl: string = '';
    public showStatisticsBubbles: boolean;
    
    public statYears: any[];
    public ssmReportYear: number = null;
    
    public applicationsTypes: any = null;
    public selectedApplicationsType: string = null;
    
    public initDownloadAllPlayersYearSSMReport() {
        this.miscService.fetchStatYears().then(data => this.statYears = data.statYears);
        this.ssmReportYear = new Date().getFullYear();
    }
    
    public downloadAllPlayersYearSSMReport() {
        this.statisticsService.downloadAllPlayersYearSSMReport(this.ssmReportYear).then(data => {
             if (data.status.code === 0) {
                if (data.file) {
                    this.ssmReportYear = null;
                    window.location.href = '/file/' + data.file;
                }
            }
        });
    }
    
    public cancelDownloadAllPlayersYearSSMReport() {
        this.ssmReportYear = null;
    }
    
    public initRouteToApplicationsList() {
        this.applicationsTypes = [
            { name: 'Podział punktów między klubami', url: 'podzial-punktow-miedzy-klubami' },
            { name: 'Naliczenie premii punktowych', url: 'naliczenie-premii-punkowych' },
            { name: 'Przeniesienie zawodnika do wyższej grupy wiekowej', url: 'zmiana-grupy-wiekowej' }
        ];
    }
    
    public routeToApplicationsList() {
        window.scrollTo(0,0);
        this.router.navigate(['/wnioski/' + this.selectedApplicationsType]);
        this.exitRouteToApplicationsList();
    }
    
    public exitRouteToApplicationsList() {
        this.applicationsTypes = null;
    }
    
	
    public ngOnInit() {
        this.sessionDataSubscription = this.sessionService.watchSessionData().subscribe(data => {
            this.loggedUser = data.user;
            this.rights = data.rights;
        });
    }
	
    public ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
        this.sessionDataSubscription.unsubscribe();
    }
    
    public routeToLoginScreen() {
        window.scrollTo(0,0);
        this.router.navigate(['/logowanie']);
    }
	
    public onLogOut() {
        this.sessionService.logOut();
    }
}
