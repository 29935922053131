import { User } from "./user";

export class Rights {
	canAdmin: boolean;
	canAdminDisc: boolean;
	adminDisciplines?: number[];

	public setRights(user: User) {
		if (user.userClass === 'SYS_ADMIN') {
			this.canAdmin = true;
			this.canAdminDisc = true;
			this.adminDisciplines = null;
		} else if (user.userClass === 'DISC_ADMIN') {
			this.canAdmin = false;
			this.canAdminDisc = true;
			this.adminDisciplines = User.getUserAdminDisciplines(user);
		} else {
			this.canAdmin = false;
			this.canAdminDisc = false;
			this.adminDisciplines = null;
		}
	}
}