import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { map, tap } from 'rxjs/operators';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Paging } from 'shared/utils/paging';

@Injectable({
	providedIn: 'root'
})
export class MiscService {
	constructor(private http: Http) {
		this.fetchDisciplines();
		this.fetchStatYears();
	}

	public statYears: number[];

	public dyscypliny: any[];

	public wojewodztwa = [
		{ nazwa: 'Dolnośląskie',			skrot: 'DŚL'  },
		{ nazwa: 'Kujawsko-pomorskie',		skrot: 'K-P'  },
		{ nazwa: 'Lubelskie',				skrot: 'LBL'  },
		{ nazwa: 'Lubuskie',				skrot: 'LBU'  },
		{ nazwa: 'Łódzkie',					skrot: 'ŁDZ'  },
		{ nazwa: 'Mazowieckie',				skrot: 'MAZ'  },
		{ nazwa: 'Małopolskie',				skrot: 'MŁP'  },
		{ nazwa: 'Opolskie',				skrot: 'OPO'  },
		{ nazwa: 'Podlaskie',				skrot: 'PDL'  },
		{ nazwa: 'Podkarpackie',			skrot: 'PKR'  },
		{ nazwa: 'Pomorskie',				skrot: 'POM'  },
		{ nazwa: 'Śląskie',					skrot: 'ŚL'   },
		{ nazwa: 'Świętokrzyskie',			skrot: 'ŚW'   },
		{ nazwa: 'Wielkopolskie',			skrot: 'WLKP' },
		{ nazwa: 'Warmińsko-mazurskie',		skrot: 'W-M'  },
		{ nazwa: 'Zachodniopomorskie',		skrot: 'ZPM'  }
	];

	public grupyWiekowe = [
		{ nazwa: 'Młodzieżowiec'  },
		{ nazwa: 'Junior'         },
		{ nazwa: 'Junior młodszy' },
		{ nazwa: 'Młodzik'        }
	];

	public resultsTypes = [
		{ name: 'INDIVIDUAL',			plName: 'Indywidualne'          },
		//{ name: 'TEAM',       			plName: 'Drużynowe'             },
		{ name: 'TEAM_CLUBS', 			plName: 'Drużynowe klubowe'     },
		{ name: 'TEAM_VOIVODESHIPS',	plName: 'Drużynowe wojewódzkie' }
	];
	
	public fetchStatYears(): Promise<any> {
		if (!this.statYears) {
			return this.http.post('/ws/stat-years/list', JSON.stringify({
			}))
			.pipe(map((response: Response) => response.json()),
			      tap(data => this.statYears = data.statYears))
			.toPromise();
		} else {
			return new Promise((resolve, reject) => resolve({ statYears: this.statYears }));
		}
	}
	
	public fetchStatYearsObjects(): any {
		return this.http.post('/ws/stat-years/objects-list', JSON.stringify({
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public getDefaultStatYear(): any {
		return this.http.post('/ws/stat-years/get-default', JSON.stringify({
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public getCurrentYear(): number {
		return new Date().getFullYear();
	}
	
	public fetchDiscipline(disciplineId: number): any {
		return this.http.post('/ws/disciplines/get', JSON.stringify({
			disciplineId: disciplineId
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchDisciplines(): Promise<any> {
		if (!this.dyscypliny) {
			return this.http.post('/ws/disciplines/list', JSON.stringify({
			}))
			.pipe(map((response: Response) => response.json()),
			      tap(data => this.dyscypliny = data.disciplines))
			.toPromise();
		} else {
			return new Promise((resolve, reject) => resolve({ disciplines: this.dyscypliny }));
		}
	}

	
	public fetchVoivideships() {
		return this.http.post('/ws/regions/voivodeships', JSON.stringify({}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchCounties(wojewodztwo: string) {
		return this.http.post('/ws/regions/counties', JSON.stringify({
			wojewodztwo: wojewodztwo
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchCommunities(wojewodztwo: string, powiat: string) {
		return this.http.post('/ws/regions/communities', JSON.stringify({
			wojewodztwo: wojewodztwo,
			powiat: powiat
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public changeTableSort(filterName: string, currentSort: string) {
		if (filterName !== '') {
			let newSort: string = '';

			if (currentSort === filterName + ' ASC')
				newSort = filterName + " DESC";
			else
				newSort = filterName + " ASC";

			return newSort;
		} else {
			return currentSort;
		}
	}
	
	public isValidPESEL(pesel: string): boolean {
		if (!pesel || pesel.length !== 11)
			return false;
		
		var rok=parseInt(pesel.substring(0,2),10);
		var miesiac = parseInt(pesel.substring(2,4),10)-1;
		var dzien = parseInt(pesel.substring(4,6),10);

		if(miesiac>80) {
			rok = rok + 1800;
			miesiac = miesiac - 80;
		}
		else if(miesiac > 60) {
			rok = rok + 2200;
			miesiac = miesiac - 60;
		}
		else if (miesiac > 40) {
			rok = rok + 2100;
			miesiac = miesiac - 40;
		}
		else if (miesiac > 20) {
			rok = rok + 2000;
			miesiac = miesiac - 20;
		}
		else
		{
			rok += 1900;
		}
		
		var urodzony=new Date();
		urodzony.setFullYear(rok, miesiac, dzien);
		
		var wagi = [9,7,3,1,9,7,3,1,9,7];
		var suma = 0;
		
		for(var i=0;i<wagi.length;i++) {
			suma+=(parseInt(pesel.substring(i,i+1),10) * wagi[i]);
		}
		suma=suma % 10;
		return (suma===parseInt(pesel.substring(10,11),10));
	}

	public isCorrectYear(year: string) {
		return Number(year) && Number(year) > 1000 && Number(year) < 9999;
	}

	public getDateFromDateTime(datetime: string) {
		return datetime.split(' ')[0];
	}
	
	public fromDateToNgbDate(date: Date | string): NgbDate {
		if (date) {
			date = typeof date === 'string' ? new Date(date) : date;
			return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
		} else {
			return null;
		}
	}

	public fromNgbDateToDate(ngbDate: NgbDate): Date {
		return ngbDate ? new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day + 1) : null;
	}

	public getRecaptchaSiteKey() {
		return this.http.post('/ws/other/get-recaptcha-site-key', JSON.stringify({
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public exportClubPointsCertificate(clubId: number, year: number) {
		return this.http.post('/ws/club/export-club-points-certificate', JSON.stringify({
			clubId: clubId,
			year: year
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public getRandomNumber(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	
	// DYMANIC CONTENTS ================================================================
	
	public fetchDynamicContents(filterIsVisible: boolean): any {
		return this.http.post('/ws/dynamic-contents/list', JSON.stringify({
			filterIsVisible: filterIsVisible
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchDynamicContent(name: string, withAttachments: boolean): any {
		return this.http.post('/ws/dynamic-contents/get', JSON.stringify({
			name: name,
			withAttachments: withAttachments
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	
	// APPLICATIONS ===============================================================
	
	public fetchPlayerClubChangeApplications(paging: Paging, filterYear: number, filterDisciplineId: number, filterLastName: string, filterStatus: string, sort: string): any {
		return this.http.post('/ws/applications/player-change-club/public-list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterYear: filterYear,
			filterDisciplineId: filterDisciplineId,
			filterLastName: filterLastName,
			filterStatus: filterStatus,
			sort: sort ? sort : ''
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchPlayerAgeGroupChangeApplications(paging: Paging, filterYear: number, filterDisciplineId: number, filterLastName: string, filterStatus: string, sort: string): any {
		return this.http.post('/ws/applications/player-change-agegroup/public-list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterYear: filterYear,
			filterDisciplineId: filterDisciplineId,
			filterLastName: filterLastName,
			filterStatus: filterStatus,
			sort: sort ? sort : ''
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchPlayerScoreBonusPointsApplications(paging: Paging, filterYear: number, filterDisciplineId: number, filterLastName: string, filterStatus: string, sort: string): any {
		return this.http.post('/ws/applications/player-score-bonus-points/public-list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterYear: filterYear,
			filterDisciplineId: filterDisciplineId,
			filterLastName: filterLastName,
			filterStatus: filterStatus,
			sort: sort ? sort : ''
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	// REGULATIONS ================================================================
	
	public fetchRegulations(year: number): any {
		return this.http.post('/ws/regulations/list', JSON.stringify({
			year: year
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
}
