<div class="card">
	<div class="card-header card-header-x pt-3 pb-4 d-flex align-items-center"></div>
	
	<div class="card-body">
		<h3>Szanowni Państwo</h3>
		<p class="text-justify">
			Zgodnie z art. 13 ust. 1 i ust. 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 
			o ochronie danych osobowych chcielibyśmy poinformować Państwa o sposobach oraz zasadach przetwarzania 
			Państwa danych osobowych na stronach naszego serwisu. Polityka prywatności Instytut Sportu – Państwowy Instytut Badawczy.
		</p>
		
		<p class="text-justify">
			Wyrażam zgodę na przetwarzanie moich danych osobowych przez Instytut Sportu – Państwowy Instytut Badawczy z siedzibą w Warszawie, 
			przy ul. Trylogii 2/16 (01-982) celem realizacji zadania związanego z organizowaniem i prowadzeniem działalności wspierającej
			i kontrolnej sportu młodzieżowego oraz w szczególności w celu rozliczenia i kontroli wykonania ww. zadania, 
			a także w związku z ewentualnym udzielaniem informacji publicznej w rozumieniu przepisów ustawy z dnia 6 września 2011 r. 
			o dostępie do informacji publicznej  (Dz.U. z 2018 r. poz. 1330 i 1669). Oświadczam, iż zapoznałem się z informacjami dotyczącymi przetwarzania danych osobowych.
		</p>
		
		<p class="text-justify">
			Cofnięcie zgody może nastąpić przez przesłanie korespondencji pocztą na adres siedziby Instytutu Sportu – Państwowego Instytutu Badawczego 
			albo za pośrednictwem poczty elektronicznej na adres: iodo@insp.waw.pl
		</p>
		
		<br>
		
		<button (click)="activeModal.dismiss('Cross click')" type="button" aria-label="Close" class="btn btn-danger float-right" style="color: white; background: #bb1111;">Akceptuję</button>
	</div>
</div>