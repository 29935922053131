<nav class="d-md-block navbar-light p-0 sidebar-nav">
	<div class="sidebar pt-4">
		<div class="clearfix d-md-none p-2">
			<button (click)="toggleCollapse()" class="navbar-toggler float-right" type="button" aria-controls="navbarTogglerDemo01"
			 aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
		</div>
		<div class="text-center" *ngIf="currentUrl === '/' ||
										currentUrl === '/logowanie' ||
										currentUrl === '/resetowanie-hasla' ||
										currentUrl.indexOf('/nowe-haslo') >= 0 ||
										currentUrl.indexOf('/aktywacja-konta') >= 0 ||
										currentUrl.indexOf('/admin') >= 0">
			<a routerLink="/">
				<img src="angular/assets/images/zsmlogo_noBG.png" alt="" style="width: 40%;">
				<div class="logo-sidebar" style="color:#00264b">
					Zespół Metodyczny
					<br>
					<small>Instytut Sportu w Warszawie</small>
					<br>
					<br>
				</div>
			</a>
		</div>

		<div class="collapse navbar-collapse menu" [class.show]="show">


			<div *ngIf="rights?.canAdmin || rights?.canAdminDisc">
				<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
					<span>
						<a routerLink="/admin">
							<div [class.activated]="currentUrl.indexOf('/admin') == 0">
								<i class="material-icons">settings</i> PANEL ADMINISTRATORA
							</div>
						</a>
					</span>
				</h6>
				<hr>
			</div>

			<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
				<span>
					<i class="material-icons">show_chart</i> STATYSTYKI
				</span>
			</h6>
			<ul class="nav flex-column">
				<li class="nav-item">
					<a routerLink="statystyki-dyscyplin" [class.activated]="currentUrl == '/statystyki-dyscyplin'" class="nav-link">
						<i class="material-icons">show_chart</i> Dyscyplin
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="statystyki-dyscyplin-grupy-wiekowe" [class.activated]="currentUrl == '/statystyki-dyscyplin-grupy-wiekowe'"
					 class="nav-link">
						<i class="material-icons">show_chart</i> Dyscyplin z podziałem na grupy wiekowe
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="statystyki-ssm" [class.activated]="currentUrl == '/statystyki-ssm'" class="nav-link">
						<i class="material-icons">show_chart</i> LICZBA UCZESTNIKÓW
					</a>
				</li>
			</ul>

			<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
				<span>
					<i class="material-icons">bar_chart</i> Klasyfikacje</span>
			</h6>
			<ul class="nav flex-column">
				<li class="nav-item">
					<a routerLink="klasyfikacja-klubow" [class.activated]="currentUrl == '/klasyfikacja-klubow'" class="nav-link">
						<i class="material-icons">bar_chart</i> Kluby
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="klasyfikacja-wojewodztw" [class.activated]="currentUrl == '/klasyfikacja-wojewodztw'" class="nav-link">
						<i class="material-icons">bar_chart</i> Województwa
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="klasyfikacja-powiatow" [class.activated]="currentUrl == '/klasyfikacja-powiatow'" class="nav-link">
						<div>
							<i class="material-icons">bar_chart</i> Powiaty
						</div>
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="klasyfikacja-gmin" [class.activated]="currentUrl == '/klasyfikacja-gmin'" class="nav-link">
						<i class="material-icons">bar_chart</i> Gminy
					</a>
				</li>
			</ul>

			<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
				<span>
					<i class="material-icons">people</i> Wykazy</span>
			</h6>
			<ul class="nav flex-column">
				<li class="nav-item">
					<a routerLink="wykaz-wydarzen" [class.activated]="currentUrl == '/wykaz-wydarzen'" class="nav-link">
						<i class="material-icons">event</i> Wydarzenia
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="wykaz-zawodnikow" [class.activated]="currentUrl == '/wykaz-zawodnikow'" class="nav-link">
						<i class="material-icons">people</i> Zawodnicy
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="wykaz-trenerow" [class.activated]="currentUrl == '/wykaz-trenerow'" class="nav-link">
						<div>
							<i class="material-icons">people</i> Trenerzy
						</div>
					</a>
				</li>
			</ul>
			
			<div *ngIf="showDynamicContentsLinks && dynamicContents && dynamicContents.length">
				<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
					<span>
						<i class="material-icons">article</i> Inne
					</span>
				</h6>
				<ul class="nav flex-column">
					<li *ngFor="let dc of dynamicContents" class="nav-item">
						<a [routerLink]="['inne', dc.url]" [class.activated]="currentUrl == '/inne' + dc.url" class="nav-link">
							<i class="material-icons">article</i> {{ dc.name }}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>