export class Alerts {
	name: { type: string, key: string, msg: string };
	city: { type: string, key: string, msg: string };
	disciplineId: { type: string, key: string, msg: string };
	ageGroup: { type: string, key: string, msg: string };
	ageGroupDates: { type: string, key: string, msg: string };
	statYear: { type: string, key: string, msg: string };
	resultsType: { type: string, key: string, msg: string };
	dateFrom: { type: string, key: string, msg: string };
	dateTo: { type: string, key: string, msg: string };
	firstname: { type: string, key: string, msg: string };
	lastname: { type: string, key: string, msg: string };
	pesel: { type: string, key: string, msg: string };
	clubId: { type: string, key: string, msg: string };
	birthYear: { type: string, key: string, msg: string };
	email: { type: string, key: string, msg: string };
	phone: { type: string, key: string, msg: string };
	wojewodztwo: { type: string, key: string, msg: string };
	powiat: { type: string, key: string, msg: string };
	gmina: { type: string, key: string, msg: string };
	numberOfPlayers: { type: string, key: string, msg: string };
	playersCountFrom: { type: string, key: string, msg: string };
	playersCountTo: { type: string, key: string, msg: string };
	rankFrom: { type: string, key: string, msg: string };
	rankTo: { type: string, key: string, msg: string };
	points: { type: string, key: string, msg: string };
	
	mlodzikBirthDateFrom: { type: string, key: string, msg: string };
	mlodzikBirthDateTo: { type: string, key: string, msg: string };
	juniorMlodszyBirthDateFrom: { type: string, key: string, msg: string };
	juniorMlodszyBirthDateTo: { type: string, key: string, msg: string };
	juniorBirthDateFrom: { type: string, key: string, msg: string };
	juniorBirthDateTo: { type: string, key: string, msg: string };
	mlodziezowiecBirthDateFrom: { type: string, key: string, msg: string };
	mlodziezowiecBirthDateTo: { type: string, key: string, msg: string };
	seniorBirthDateFrom: { type: string, key: string, msg: string };
	seniorBirthDateTo: { type: string, key: string, msg: string };
	
	playerId: { type: string, key: string, msg: string };
	motherClubId: { type: string, key: string, msg: string };
	currentClubId: { type: string, key: string, msg: string };
	clubShare: { type: string, key: string, msg: string };
	
	aktualnaGrupaWiekowa: { type: string, key: string, msg: string };
	wnioskowanaGrupaWiekowa: { type: string, key: string, msg: string };

	file: { type: string, key: string, msg: string };
	save: { type: string, key: string, msg: string };
	
	liczbaPunktow: { type: string, key: string, msg: string };
	
	uzasadnienieWniosku: { type: string, key: string, msg: string };
	uzasadnienieDecyzji: { type: string, key: string, msg: string };

	minLiczbaZawodnikow: { type: string, key: string, msg: string };
	minLiczbaZespolow: { type: string, key: string, msg: string };
	brakPunktowDlaOstatniegoJesliWiecejPunktownychMiejsc: { type: string, key: string, msg: string };
	mlodziezowiecPolowaPunktowJestliMniejNiz8: { type: string, key: string, msg: string };
	mlodzikMinLiczbaWojewodztw: { type: string, key: string, msg: string };
	mlodzikMinLiczbaKlubow: { type: string, key: string, msg: string };
	mlodzikJuniorMlodszyMaxKonkurencji: { type: string, key: string, msg: string };
	juniorMlodziezowiecMaxKonkurencji: { type: string, key: string, msg: string };
	
	currentPassword: { type: string, key: string, msg: string };
	newPassword1: { type: string, key: string, msg: string };
	newPassword2: { type: string, key: string, msg: string };
	
	
	eventId: { type: string, key: string, msg: string };
	
	
	sourceClubId: { type: string, key: string, msg: string };
	targetClubId: { type: string, key: string, msg: string };
	sameClub: { type: string, key: string, msg: string };
	
	sourcePlayerId: { type: string, key: string, msg: string };
	targetPlayerId: { type: string, key: string, msg: string };
	samePlayer: { type: string, key: string, msg: string };
	
	results: { type: string, key: string, msg: string };
	
	collision: { type: string, key: string, msg: string };
	
	ageGroupStartExceptionsBirthDateLimit: { type: string, key: string, msg: string };

	text: { type: string, key: string, msg: string };
	recaptchaToken: { type: string, key: string, msg: string };
	
	applicationAlreadySubmitted: { type: string, key: string, msg: string };
	
	title: { type: string, key: string, msg: string };
	
	saveCoachForm: { type: string, key: string, msg: string };
}