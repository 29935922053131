import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Rights } from 'shared/model/rights';
import { DynamicContent } from 'shared/model/dynamicContent';
import { Subscription } from 'rxjs';

import { MiscService } from 'shared/services/misc.service';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
	@Input() rights: Rights;
	@Input() currentUrl: string;
	
	constructor(private router: Router, private miscService: MiscService) {
		this.routeSubscription = router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				this.showDynamicContentsLinks = e.url !== '/';
			}
		})
	}
	
	private routeSubscription: Subscription;
	
	public dynamicContents: DynamicContent[];
	public showDynamicContentsLinks: boolean;
	
	ngOnInit() {
		this.miscService.fetchDynamicContents(true).then(data => {
            this.dynamicContents = data.dynamicContents;
        })
	}
	
	public ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
	}
	
	show: boolean = false;

	toggleCollapse() {
		this.show = !this.show
	}
}
