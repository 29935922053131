import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SessionService } from 'shared/services/session.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
	constructor(private sessionsService: SessionService, private router: Router) {}
	
    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		let loggedUser = await this.sessionsService.getUser();
		let targetUrl = state.url;
		
		if (targetUrl === '/logowanie' && loggedUser) {
			this.router.navigate(['']);
			return false;
		}

		return true;
	}
}
