import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { map } from 'rxjs/operators';

import { Paging } from 'shared/utils/paging';
import { ScoredPointsNote } from 'shared/model/scoredPointsNote';
import { ScoredPointsNoteComment } from 'shared/model/scoredPointsNoteComment';

@Injectable({
  	providedIn: 'root'
})
export class StatisticsService {
	constructor(private http: Http) { }

	// STATISTICS ======================================================================
	
	public fetchStatistic(paging: Paging, filterStatYear: number, filterDisciplineName: string, sort: string, needSum: boolean): any {
		return this.http.post('/ws/statistics/list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterDisciplineName: filterDisciplineName,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchStatisticAgeGroups(paging: Paging, filterStatYear: number, filterDisciplineName: string, sort: string, needSum: boolean): any {
		return this.http.post('/ws/statistics/agegroups/list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterDisciplineName: filterDisciplineName,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
  
	public fetchSsmStatistics(filterStatYear: number, filterDisciplineId: number, sort: string): any {
		return this.http.post('/ws/statistics/ssm/list', JSON.stringify({
			filterStatYear: filterStatYear,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchSsmStatisticsChart(filterDisciplineId: number, filterVoivodeship: string, filterWskaznik: string): any {
		return this.http.post('/ws/statistics/ssm/chart', JSON.stringify({
			filterDisciplineId: filterDisciplineId,
			filterVoivodeship: filterVoivodeship,
			filterWskaznik: filterWskaznik
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	
	// EVENTS ==========================================================================
	
	public fetchEventsStatistics(paging: Paging, filterStatYear: number, filterEventName: string, filterAgeGroup: string, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/statistics/events/list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterEventName: filterEventName,
			filterAgeGroup: filterAgeGroup,
			filterDisciplineId: filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchEventStatistics(eventId: number): any {
		return this.http.post('/ws/statistics/events/get', JSON.stringify({
			eventId: eventId
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	
	// CLUBS ===========================================================================
	
	public fetchClassificationClubs(paging: any, filterStatYear: number, filterClubName: string, filterVoivodeship: string, filterCounty: string, filterCommunity: string, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/clubs/list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterClubName: filterClubName,
			filterVoivodeship: filterVoivodeship,
			filterCounty: filterCounty,
			filterCommunity: filterCommunity,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchClub(clubId: number): any {
		return this.http.post('/ws/statistics/clubs/get', JSON.stringify({
			clubId: clubId
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public subscribeClubResults(clubId: number, email: string, recaptchaToken: string) {
		return this.http.post('/ws/statistics/clubs/subscribe', JSON.stringify({
			clubId: clubId,
			email: email,
			recaptchaToken
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public unsubscribeClubResults(unsubscribeToken: string) {
		return this.http.post('/ws/statistics/clubs/unsubscribe', JSON.stringify({
			unsubscribeToken: unsubscribeToken
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchClubSSMHistory(clubId: number, paging: Paging, filterStatYear: number, filterEventName: string, filterAgeGroup: string, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/statistics/club/ssm-history', JSON.stringify({
			clubId: clubId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterEventName: filterEventName,
			filterAgeGroup: filterAgeGroup,
			filterDisciplineId: filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchClubPlayers(clubId: number, paging: Paging, filterNazwisko: string, filterRokUrodz: string, filterDisciplineId: number, filterStatYear: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/statistics/club/players', JSON.stringify({
			clubId: clubId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterNazwisko: filterNazwisko,
			filterRokUrodz: filterRokUrodz,
			filterDisciplineId: filterDisciplineId,
			filterStatYear: filterStatYear,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchClubCoaches(clubId: number, paging: Paging, filterNazwisko: string, filterRokUrodz: string, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/statistics/club/coaches', JSON.stringify({
			clubId: clubId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterNazwisko: filterNazwisko,
			filterRokUrodz: filterRokUrodz,
			filterDisciplineId: filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	
	// VOIVODSHIPS =====================================================================
	
	public fetchClassificationVoivodships(paging: Paging, filterStatYear: number, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/voivodeships/list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchVoivodeship(wojewodztwoId: number): any {
		return this.http.post('/ws/classifications/voivodeship/get', JSON.stringify({
			wojewodztwoId: wojewodztwoId
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchVoivideshipCountiesClassification(wojewodztwoId: number, paging: Paging, filterCounty: string, filterStatYear: number, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/voivodeship/counties-classification', JSON.stringify({
			wojewodztwoId: wojewodztwoId,
			filterCounty: filterCounty,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchVoivideshipCommunitiesClassification(wojewodztwoId: number, paging: Paging, filterCounty: string, filterCommunity: string, filterStatYear: number, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/voivodeship/communities-classification', JSON.stringify({
			wojewodztwoId: wojewodztwoId,
			filterCounty: filterCounty,
			filterCommunity: filterCommunity,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchVoivideshipClubsClassification(wojewodztwoId: number, paging: any, filterStatYear: number, filterClubName: string, filterCounty: string, filterCommunity: string, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/voivodeship/clubs-classification', JSON.stringify({
			wojewodztwoId: wojewodztwoId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterClubName: filterClubName,
			filterCounty: filterCounty,
			filterCommunity: filterCommunity,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchVoivideshipDisciplinesClassification(wojewodztwoId: number, paging: Paging, filterStatYear: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/voivodeship/disciplines-classification', JSON.stringify({
			wojewodztwoId: wojewodztwoId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchVoivideshipIndividualClassification(wojewodztwoId: number, paging: Paging, filterNazwisko: string, filterRokUrodz: string, filterDisciplineId: number, filterAgeGroup: string, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/voivodeship/individual-classification', JSON.stringify({
			wojewodztwoId: wojewodztwoId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterNazwisko: filterNazwisko,
			filterRokUrodz: filterRokUrodz,
			filterDisciplineId: filterDisciplineId,
			filterAgeGroup: filterAgeGroup,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	// COUNTIES ========================================================================
	
	public fetchClassificationCounties(paging: Paging, filterStatYear: number, filterVoivodeship: string, filterCounty: string, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/counties/list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterVoivodeship: filterVoivodeship,
			filterCounty: filterCounty,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchCounty(powiatId: number): any {
		return this.http.post('/ws/classifications/county/get', JSON.stringify({
			powiatId: powiatId
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchCountyCommunitiesClassification(powiatId: number, filterCommunity: string, paging: Paging, filterStatYear: number, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/county/communities-classification', JSON.stringify({
			powiatId: powiatId,
			filterCommunity: filterCommunity,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchCountyClubsClassification(powiatId: number, paging: any, filterStatYear: number, filterClubName: string, filterCommunity: string, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/county/clubs-classification', JSON.stringify({
			powiatId: powiatId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterClubName: filterClubName,
			filterCommunity: filterCommunity,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchCountyDisciplinesClassification(powiatId: number, paging: Paging, filterStatYear: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/county/disciplines-classification', JSON.stringify({
			powiatId: powiatId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchCountyIndividualClassification(powiatId: number, paging: Paging, filterNazwisko: string, filterRokUrodz: string, filterDisciplineId: number, filterAgeGroup: string, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/county/individual-classification', JSON.stringify({
			powiatId: powiatId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterNazwisko: filterNazwisko,
			filterRokUrodz: filterRokUrodz,
			filterDisciplineId: filterDisciplineId,
			filterAgeGroup: filterAgeGroup,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	// COMMUNITIES =====================================================================
	
	public fetchClassificationCommunities(paging: Paging, filterStatYear: number, filterVoivodeship: string, filterCounty: string, filterCommunity: string, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/communities/list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterVoivodeship: filterVoivodeship,
			filterCounty: filterCounty,
			filterCommunity: filterCommunity,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchCommunity(gminaId: number): any {
		return this.http.post('/ws/classifications/community/get', JSON.stringify({
			gminaId: gminaId
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchCommunityClubsClassification(gminaId: number, paging: any, filterStatYear: number, filterClubName: string, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/community/clubs-classification', JSON.stringify({
			gminaId: gminaId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterClubName: filterClubName,
			filterDisciplineId: filterDisciplineId == 0 ? null : filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchCommunityDisciplinesClassification(gminaId: number, paging: Paging, filterStatYear: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/community/disciplines-classification', JSON.stringify({
			gminaId: gminaId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public fetchCommunityIndividualClassification(gminaId: number, paging: Paging, filterNazwisko: string, filterRokUrodz: string, filterDisciplineId: number, filterAgeGroup: string, sort: string, needSum: boolean): any {
		return this.http.post('/ws/classifications/community/individual-classification', JSON.stringify({
			gminaId: gminaId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterNazwisko: filterNazwisko,
			filterRokUrodz: filterRokUrodz,
			filterDisciplineId: filterDisciplineId,
			filterAgeGroup: filterAgeGroup,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	// PLAYERS =========================================================================
	
	public fetchPlayers(paging: Paging, filterNazwisko: string, filterRokUrodz: string, filterDisciplineId: number, filterAgeGroup: string, sort: string, needSum: boolean): any {
		return this.http.post('/ws/statistics/players/list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterNazwisko: filterNazwisko,
			filterRokUrodz: filterRokUrodz,
			filterDisciplineId: filterDisciplineId,
			filterAgeGroup: filterAgeGroup,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchPlayer(playerId: number): any {
		return this.http.post('/ws/statistics/players/get', JSON.stringify({
			playerId: playerId
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchPlayerTakenPlaces(playerId: number, paging: Paging, filterStatYear: number, filterEventName: string, filterAgeGroup: string, filterCompetitionName: string, filterClubName: string, filterDisciplineId: number, sort: string): any {
		return this.http.post('/ws/statistics/player/taken-places', JSON.stringify({
			playerId: playerId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterEventName: filterEventName,
			filterAgeGroup: filterAgeGroup,
			filterDisciplineId: filterDisciplineId,
			filterCompetitionName: filterCompetitionName,
			filterClubName: filterClubName,
			sort: sort
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchPlayerHistoryOfClubCoaches(playerId: number, paging: Paging, filterStatYear: number, filterNazwisko: string, filterClubName: string, filterEventName: string, filterAgeGroup: string, filterCompetitionName: string, filterDisciplineId: number, sort: string): any {
		return this.http.post('/ws/statistics/player/club-coaches-history', JSON.stringify({
			playerId: playerId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterNazwisko: filterNazwisko,
			filterClubName: filterClubName,
			filterDisciplineId: filterDisciplineId,
			filterEventName: filterEventName,
			filterAgeGroup: filterAgeGroup,
			filterCompetitionName: filterCompetitionName,
			sort: sort
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchPlayerHistoryOfVoivodeshipCoaches(playerId: number, paging: Paging, filterStatYear: number, filterNazwisko: string, filterClubName: string, filterEventName: string, filterAgeGroup: string, filterCompetitionName: string, filterDisciplineId: number, sort: string): any {
		return this.http.post('/ws/statistics/player/voivodeship-coaches-history', JSON.stringify({
			playerId: playerId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterStatYear: filterStatYear,
			filterNazwisko: filterNazwisko,
			filterClubName: filterClubName,
			filterDisciplineId: filterDisciplineId,
			filterEventName: filterEventName,
			filterAgeGroup: filterAgeGroup,
			filterCompetitionName: filterCompetitionName,
			sort: sort
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchPlayerChangeAgeGroupApplications(playerId: number, paging: Paging, sort: string): any {
		return this.http.post('/ws/statistics/player/change-age-group-applications', JSON.stringify({
			playerId: playerId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			sort: sort
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	
	// COACHES =========================================================================
	
	public fetchCoaches(paging: Paging, filterNazwisko: string, filterRokUrodz: string, filterDisciplineId: number, sort: string, needSum: boolean): any {
		return this.http.post('/ws/statistics/coaches/list', JSON.stringify({
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterNazwisko: filterNazwisko,
			filterRokUrodz: filterRokUrodz,
			filterDisciplineId: filterDisciplineId,
			sort: sort,
			needSum: needSum
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchCoach(coachId: number): any {
		return this.http.post('/ws/statistics/coaches/get', JSON.stringify({
			coachId: coachId
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchCoachEvents(coachId: number, paging: Paging, filterDisciplineId: number, filterEventName: string, filterStatYear: number, filterAgeGroup: string, sort: string): any {
		return this.http.post('/ws/statistics/coach/events', JSON.stringify({
			coachId: coachId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterDisciplineId: filterDisciplineId,
			filterEventName: filterEventName,
			filterStatYear: filterStatYear,
			filterAgeGroup: filterAgeGroup,
			sort: sort
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchCoachPlayers(coachId: number, paging: Paging, filterDisciplineId: number, filterNazwisko: string, filterRokUrodz: string, sort: string): any {
		return this.http.post('/ws/statistics/coach/players', JSON.stringify({
			coachId: coachId,
			pageSize: paging.pageSize,
			page: paging.currentPage,
			count: paging.needCount,
			filterDisciplineId: filterDisciplineId,
			filterNazwisko: filterNazwisko,
			filterRokUrodz: filterRokUrodz,
			sort: sort
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	
	// SCORED POINTS NOTES/COMMENTS ====================================================
	
	public saveScoredPointsNote(scoredPointsNote: ScoredPointsNote, recaptchaToken: string): any {
		return this.http.post('/ws/applications/scored-points-notes/save', JSON.stringify({
			scoredPointsNote: scoredPointsNote,
			recaptchaToken: recaptchaToken
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public saveScoredPointsNoteComment(scoredPointsNoteComment: ScoredPointsNoteComment, token: string, skipEmailNotification: boolean): any {
		return this.http.post('/ws/applications/scored-points-note-comments/save', JSON.stringify({
			scoredPointsNoteComment: scoredPointsNoteComment,
			token: token,
			status: scoredPointsNoteComment.status,
			skipEmailNotification: skipEmailNotification
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public getScoredPointsNoteComments(token: string): any {
		return this.http.post('/ws/applications/scored-points-note-comments/list', JSON.stringify({
			token: token,
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	
	// OTHER ===========================================================================
	
	public fetchStatisticsBubbles(statYear?: number) {
		return this.http.post('/ws/statistics/bubbles', JSON.stringify({
			statYear: statYear
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}

	public fetchLastAddedResults() {
		return this.http.post('/ws/statistics/last-added-results/list', JSON.stringify({}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
	
	public downloadAllPlayersYearSSMReport(year: number){
		return this.http.post('/ws/statistics/download-all-players-year-ssm-report', JSON.stringify({
			year: year
		}))
		.pipe(map((response: Response) => response.json()))
		.toPromise();
	}
}
