import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CookieService } from 'ngx-cookie-service';
import { RecaptchaModule } from 'ng-recaptcha';

// modules
import { AppRoutingModule } from './/app-routing.module';
import { LoginModule } from './login/login.module';
import { HomeModule } from './content/home/home.module';
import { UtilsModule } from 'shared/utils/utils.module';

// services
import { SessionService } from './shared/services/session.service';

// components
import { AppComponent } from './app.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { PageNotFoundComponent } from './content/page-not-found/page-not-found.component';
import { StatystykiDymkiComponent } from './statystyki-dymki/statystyki-dymki.component';
import { RodoComponent } from './content/rodo/rodo.component';
import { WnioskiPodzialPunktowMiedzyKlubamiComponent } from 'content/wnioski/wnioski-podzial-punktow-miedzy-klubami/wnioski-podzial-punktow-miedzy-klubami.component';
import { WnioskiNaliczeniePremiiPunktowychComponent } from 'content/wnioski/wnioski-naliczenie-premii-punktowych/wnioski-naliczenie-premii-punktowych.component';
import { WnioskiZmianaGrupyWiekowejComponent } from 'content/wnioski/wnioski-zmiana-grupy-wiekowej/wnioski-zmiana-grupy-wiekowej.component';

@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent,
    PageNotFoundComponent,
    StatystykiDymkiComponent,
    RodoComponent,
    WnioskiPodzialPunktowMiedzyKlubamiComponent,
    WnioskiNaliczeniePremiiPunktowychComponent,
    WnioskiZmianaGrupyWiekowejComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    NgbModule,
    EditorModule,
    RecaptchaModule,
    AppRoutingModule,
    LoginModule,
    HomeModule,
    UtilsModule
  ],
  providers: [
    CookieService,
    SessionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
