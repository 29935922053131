import { Component, OnInit } from '@angular/core';

import { StatisticsBubbles } from 'shared/model/statisticsBubbles';

import { StatisticsService } from 'shared/services/statistics.service';
import { MiscService } from 'shared/services/misc.service';

@Component({
	selector: 'statystyki-dymki',
	templateUrl: './statystyki-dymki.component.html',
	styleUrls: ['./statystyki-dymki.component.scss']
})
export class StatystykiDymkiComponent implements OnInit {
	constructor(private statisticsService: StatisticsService, private miscService: MiscService) { }

	public statYears: number[];

	public filterStatYear: number;

	public statystykiDymki: StatisticsBubbles;

	onSelectStatYear(statYear) {
		this.filterStatYear = statYear;
		this.loadBubblesData();
	}

	ngOnInit() {
		this.miscService.fetchStatYears().then(data => this.statYears = data.statYears);
		
		this.miscService.getDefaultStatYear().then(data => {
			this.filterStatYear = data.defaultStatYear;
			this.loadBubblesData();
		})
	}

	private loadBubblesData() {
		this.statisticsService.fetchStatisticsBubbles(this.filterStatYear).then(data => {
			this.statystykiDymki = data.statystykiDymki ? data.statystykiDymki : new StatisticsBubbles();
		})
	}
}
