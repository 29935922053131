import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'lineBreak'
})

@Injectable()
export class LineBreakPipe implements PipeTransform {
	transform(value: string): string {
        return value ? value.replace(new RegExp('/\n/', 'g'), '<br/>') : '';
    };
}