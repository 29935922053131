export class StatisticsBubbles {
    id?: number;
    statYear: number;
    punktyDoZdobycia: number;
    punktyZaliczone: number;
    liczbaWydarzen: number;
    liczbaZawodnikow: number;
    liczbaKlubow: number;
    liczbaWojewodztw: number;
    liczbaPowiatow: number;
    liczbaGmin: number;

    constructor() {
        this.punktyDoZdobycia = 0
        this.punktyZaliczone = 0
        this.liczbaWydarzen = 0
        this.liczbaZawodnikow = 0
        this.liczbaKlubow = 0
        this.liczbaWojewodztw = 0
        this.liczbaPowiatow = 0
        this.liczbaGmin = 0
    }
}


	