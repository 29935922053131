<div class="card mt-3 mb-2">
	<div class="card-header">RODO - Informacja o przetwarzaniu danych osobowych</div>
	
	<div class="card-body">
		<h6>1. Tożsamość administratora</h6>
		<p class="text-justify">
			Administratorem Pani/Pana danych osobowych jest Instytut Sportu – Państwowy Instytut Badawczy, z siedzibą w Warszawie, przy ul. Trylogii 2/16 (01-982).
		</p>
		
		<h6>2. Dane kontaktowe administratora</h6>
		<p class="text-justify">
			Dane do kontaktu w Instytucie Sportu – Państwowym Instytucie Badawczym sprawie ochrony danych osobowych:<br>
			adres e-mail: iodo@insp.waw.pl
		</p>
		
		<h6>3. Dane kontaktowe </h6>
		<p class="text-justify">
			W sprawie przetwarzania danych osobowych można się kontaktować pod adresem email: iodo@insp.waw.pl
		</p>
		
		<h6>4. Cele przetwarzania i podstawa prawna przetwarzania</h6>
		<p class="text-justify">
			Pani/Pana dane są przetwarzane w związku z udzieleniem przez Ministra Sportu dotacji celowej na podstawie art. 86 ust. 4 
			ustawy z dnia 19 listopada 2009 r. o grach hazardowych (Dz.U. z  2019 poz. 847), dot. realizacji zadania związanego z organizowaniem 
			i prowadzeniem działalności wspierającej i kontrolnej sportu młodzieżowego oraz w szczególności w celu rozliczenia i kontroli wykonania ww. zadania, 
			a także w związku z ewentualnym udzielaniem informacji publicznej w rozumieniu przepisów ustawy z dnia 6 września 2011 r. 
			o dostępie do informacji publicznej (Dz.U. z 2018 r. poz. 1330 i 1669) dotyczącej ww. zadania.
		</p>
		
		<h6>5. Odbiorcy danych lub kategorie odbiorców danych</h6>
		<p class="text-justify">
			Nie planujemy przekazywania Pani/Pana danych innym odbiorcom danych, za wyjątkiem Ministra Sportu oraz urzędu obsługującego 
			Ministra Sportu, jakim jest Ministerstwo Sportu, organów prowadzącym postępowania kontrolne w zakresie zadania zleconego 
			do realizacji, a także w związku z ewentualnym udzielaniem informacji publicznej w rozumieniu przepisów ustawy z 6 września 2011 r. o dostępie 
			do informacji publicznej w zakresie dotyczącym ww. zadania.
		</p>
		
		<h6>6. Okres przechowywania danych</h6>
		<p class="text-justify">
			Dane będą przetwarzane przez okres niezbędny do realizacji obowiązków wynikających z przepisów powszechnie obowiązującego prawa 
			w zakresie niezbędnym do realizacji celów przetwarzania określonych w pkt 4 powyżej oraz dla wypełnienia obowiązków archiwizacyjnych, 
			wskazanych w przepisach o archiwizacji.
		</p>
		
		<h6>7. Prawa podmiotów danych</h6>
		<p class="text-justify">
			Przysługuje Pani/Panu prawo dostępu do Pani/Pana danych oraz prawo żądania ich sprostowania, ograniczenia przetwarzania lub usunięcia po okresie, 
			o którym mowa powyżej. Ponadto przysługuje Pani/Panu prawo do wniesienia sprzeciwu wobec przetwarzania Pani/Pana danych osobowych.
		</p>
		
		<h6>8. Prawo wniesienia skargi do organu nadzorczego</h6>
		<p class="text-justify">
			Przysługuje Pani/Panu również prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych w państwie członkowskim 
			Pani/Pana zwykłego pobytu, miejsca pracy lub miejsca popełnienia domniemanego naruszenia.
			
			<br><br>
			
			Biuro Prezesa Urzędu Ochrony Danych Osobowych (PUODO)<br>
			Adres: Stawki 2, 00-193 Warszawa<br>
			Telefon: 22 860 70 86
		</p>
		
		<h6>9. Informacja o dobrowolności lub obowiązku podania danych</h6>
		<p class="text-justify">
			Pani/Pana dane osobowe są przetwarzane na podstawie zgody, której udzielenie warunkuje udział w projekcie. 
			Podanie przez Panią/Pana danych osobowych jest dobrowolne, a niezbędne do prawidłowej realizacji zadania związanego 
			z organizowaniem i prowadzeniem działalności wspierającej i kontrolnej sportu młodzieżowego.
		</p>
	</div>
</div>