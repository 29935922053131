<!-- Begin page content -->
<div class="container-fluid">
    <statystyki-dymki *ngIf="showStatisticsBubbles"></statystyki-dymki>
    <div class="row">
        <app-side-menu class="col-md-3 col-lg-2" [rights]="rights" [currentUrl]="currentUrl"></app-side-menu>
		
        <main role="main" class="col-md-9 col-lg-10 p-4">
            <router-outlet></router-outlet>
        </main>
		
        <!-- Footer -->
        <footer class="footer">
            <div class="footer-flex">
                <div>
                    <div class="copy text-muted">Copyright &copy; 2018 Zespół Metodyczny. Instytut Sportu w Warszawie</div>
                    
                    <ul>
                        <li>
                            <a [routerLink]="['/rodo']">Klauzula informacyjna RODO</a>
                        </li>
                        <li>
                            <a href="#">Partnerzy</a>
                        </li>
                        <li>
                            <a href="#">Kontakt</a>
                        </li>
                        <li>
                            <span *ngIf="loggedUser"> {{loggedUser.email}}</span>
                        </li>
                        <li>
                            <a *ngIf="!loggedUser" (click)="routeToLoginScreen()" href="javascript:void(0)">Logowanie</a>
                            <a *ngIf="loggedUser" (click)="onLogOut()" href="#">Wyloguj</a>
                        </li>
                    </ul>
                </div>
                
                <div class="ssm-report">
                    <span>
                        <a href="javascript:void(0);" *ngIf="!applicationsTypes" (click)="initRouteToApplicationsList()" class="ssm-report-btn">Wnioski</a>
                        <span *ngIf="applicationsTypes" class="text-muted" style="margin-right: 15px;">Wnioski</span>
                        
                        <select *ngIf="applicationsTypes" id="selectStatYear" [(ngModel)]="selectedApplicationsType" (change)="routeToApplicationsList()" style="margin-right:10px; margin-top: 6px;" title="wybierz typ wniosków">
                            <option *ngFor="let at of applicationsTypes" [ngValue]="at.url">{{ at.name }}</option>
                        </select>
                        
                        <a href="javascript:void(0);" *ngIf="applicationsTypes" (click)="exitRouteToApplicationsList()" style="margin-right:10px;" title="anuluj">
                            <i class="material-icons">clear</i>
                        </a>
                    </span>
                    <span>
                        <a href="javascript:void(0);" *ngIf="!ssmReportYear" (click)="initDownloadAllPlayersYearSSMReport()" class="ssm-report-btn">Raport SSM</a>
                        <span *ngIf="ssmReportYear" class="text-muted" style="margin-right: 15px;">Raport SSM</span>
                        
                        <select *ngIf="ssmReportYear" id="selectStatYear" [(ngModel)]="ssmReportYear" style="margin-right:10px; margin-top: 6px;" title="wybierz rok">
                            <option *ngFor="let statYear of statYears" [ngValue]="statYear">{{statYear}}</option>
                        </select>
                        
                        <a href="javascript:void(0);" *ngIf="ssmReportYear" (click)="downloadAllPlayersYearSSMReport()" style="margin-right:10px;" title="pobierz">
                            <i class="material-icons">get_app</i>
                        </a>
                        <a href="javascript:void(0);" *ngIf="ssmReportYear" (click)="cancelDownloadAllPlayersYearSSMReport()" style="margin-right:10px;" title="anuluj">
                            <i class="material-icons">clear</i>
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    </div>
</div>