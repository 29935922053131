import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// components
import { HomeMainComponent } from './content/home/home-main/home-main.component';
import { LoginComponent } from './login/login/login.component';
import { RodoComponent } from 'content/rodo/rodo.component';
import { PageNotFoundComponent } from './content/page-not-found/page-not-found.component';
import { WnioskiPodzialPunktowMiedzyKlubamiComponent } from 'content/wnioski/wnioski-podzial-punktow-miedzy-klubami/wnioski-podzial-punktow-miedzy-klubami.component';
import { WnioskiNaliczeniePremiiPunktowychComponent } from 'content/wnioski/wnioski-naliczenie-premii-punktowych/wnioski-naliczenie-premii-punktowych.component';
import { WnioskiZmianaGrupyWiekowejComponent } from 'content/wnioski/wnioski-zmiana-grupy-wiekowej/wnioski-zmiana-grupy-wiekowej.component';

// guards
import { LoginGuard } from 'shared/guards/login.guard';
import { AdminGuard } from 'shared/guards/admin.guard';
import { DynamicContentMainComponent } from 'content/dymanic-content/dynamic-content-main/dynamic-content-main.component';

const routes: Routes = [
	{
		path: '',
		component: HomeMainComponent
	},
	{
		path: 'inne/:dynamicContentName',
		component: DynamicContentMainComponent
	},
	{
		path: 'logowanie',
		component: LoginComponent,
		canActivate: [LoginGuard]
	},
	{
		path: 'resetowanie-hasla',
		component: LoginComponent,
		canActivate: [LoginGuard]
	},
	{
		path: 'nowe-haslo/:passResetToken',
		component: LoginComponent,
		canActivate: [LoginGuard]
	},
	{
		path: 'aktywacja-konta/:passResetToken',
		component: LoginComponent,
		canActivate: [LoginGuard]
	},
	{
		path: 'rodo',
		component: RodoComponent
	},
	{
		path: 'admin',
		loadChildren: './admin/admin.module#AdminModule',
		canLoad: [AdminGuard]
	},
	{
		path: 'statystyki-dyscyplin',
		loadChildren: './content/statystyki-dyscyplin/statystyki-dyscyplin.module#StatystykiDyscyplinModule'
	},
	{
		path: 'wykaz-wydarzen-w-dyscyplinie',
		loadChildren: './content/wykaz-wydarzen-w-dyscyplinie/wykaz-wydarzen-w-dyscyplinie.module#WykazWydarzenWDyscyplinieModule'
	},
	{
		path: 'statystyki-dyscyplin-grupy-wiekowe',
		loadChildren: './content/statystyki-dyscyplin-grupy-wiekowe/statystyki-dyscyplin-grupy-wiekowe.module#StatystykiDyscyplinGrupyWiekoweModule'
	},
	{
		path: 'klasyfikacja-klubow',
		loadChildren: './content/klasyfikacja-klubow/klasyfikacja-klubow.module#KlasyfikacjaKlubowModule'
	},
	{
		path: 'klub',
		loadChildren: './content/klub/klub.module#KlubModule'
	},
	{
		path: 'klasyfikacja-wojewodztw',
		loadChildren: './content/klasyfikacja-wojewodztw/klasyfikacja-wojewodztw.module#KlasyfikacjaWojewodztwModule'
	},
	{
		path: 'wojewodztwo',
		loadChildren: './content/wojewodztwo/wojewodztwo.module#WojewodztwoModule'
	},
	{
		path: 'klasyfikacja-powiatow',
		loadChildren: './content/klasyfikacja-powiatow/klasyfikacja-powiatow.module#KlasyfikacjaPowiatowModule'
	},
	{
		path: 'powiat',
		loadChildren: './content/powiat/powiat.module#PowiatModule'
	},
	{
		path: 'klasyfikacja-gmin',
		loadChildren: './content/klasyfikacja-gmin/klasyfikacja-gmin.module#KlasyfikacjaGminModule'
	},
	{
		path: 'gmina',
		loadChildren: './content/gmina/gmina.module#GminaModule'
	},
	{
		path: 'wykaz-wydarzen',
		loadChildren: './content/wykaz-wydarzen/wykaz-wydarzen.module#WykazWydarzenModule'
	},
	{
		path: 'wydarzenie',
		loadChildren: './content/wydarzenie/wydarzenie.module#WydarzenieModule'
	},
	{
		path: 'wykaz-zawodnikow',
		loadChildren: './content/wykaz-zawodnikow/wykaz-zawodnikow.module#WykazZawodnikowModule'
	},
	{
		path: 'zawodnik',
		loadChildren: './content/zawodnik/zawodnik.module#ZawodnikModule'
	},
	{
		path: 'wykaz-trenerow',
		loadChildren: './content/wykaz-trenerow/wykaz-trenerow.module#WykazTrenerowModule'
	},
	{
		path: 'trener',
		loadChildren: './content/trener/trener.module#TrenerModule'
	},
	{
		path: 'statystyki-ssm',
		loadChildren: './content/statystyki-ssm/statystyki-ssm.module#StatystykiSSMModule'
	},
	{
		path: 'wnioski/podzial-punktow-miedzy-klubami',
		component: WnioskiPodzialPunktowMiedzyKlubamiComponent
	},
	{
		path: 'wnioski/naliczenie-premii-punkowych',
		component: WnioskiNaliczeniePremiiPunktowychComponent
	},
	{
		path: 'wnioski/zmiana-grupy-wiekowej',
		component: WnioskiZmianaGrupyWiekowejComponent
	},
	{
		path: '**',
		component: PageNotFoundComponent
	}
];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule { }
