import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';

import { RodoModalComponent } from '../rodo-modal/rodo-modal.component';
import { StatisticsBubbles } from 'shared/model/statisticsBubbles';

import { StatisticsService } from 'shared/services/statistics.service';
import { MiscService } from 'shared/services/misc.service';
import { DynamicContent } from 'shared/model/dynamicContent';
import { Regulation } from 'shared/model/regulation';

@Component({
	templateUrl: './home-main.component.html',
	styleUrls: ['./home-main.component.scss']
})
export class HomeMainComponent implements OnInit, AfterViewInit {
	constructor(private modalService: NgbModal, private cookieService: CookieService,
				private statisticsService: StatisticsService, private miscService: MiscService) {}
	
	public statystykiDymki: StatisticsBubbles;
	public ostatnieWyniki: any[];
	public dynamicContents: DynamicContent[];
	
	public imageNumber: number = 0;
	
	public regulations: Regulation[];
	public mainRegulations: Regulation[];
	public discRegulationsSwitcher: Regulation;
	public discRegulations: Regulation[];
	public showDiscRegulations : boolean = false;
	
	public years: any[];
	public year: number;
	
	onChangeYear() {
		this.getRegulations();
	}
	
	ngOnInit() {
		this.imageNumber = this.miscService.getRandomNumber(0, 27);
		
		this.statisticsService.fetchStatisticsBubbles().then(data => {
			this.statystykiDymki = data.statystykiDymki ? data.statystykiDymki : new StatisticsBubbles();
		})
		
		this.statisticsService.fetchLastAddedResults().then(data => {
			this.ostatnieWyniki = data.ostatnieWyniki;
		})
		
		this.miscService.fetchDynamicContents(true).then(data => {
			this.dynamicContents = data.dynamicContents;
		})
		
		this.miscService.fetchStatYearsObjects().then(data => {
			this.years = data.statYears;
			
			if (this.years && this.years.length) {
				this.years.forEach(year => {
					if (year.isDefault) {
						this.year = year.year;
						this.getRegulations();
					}
				})
			}
		});
	}
	
	ngAfterViewInit(): void {
		let rodoModalClosed: boolean = this.cookieService.get('rodoModalClosed') ? JSON.parse(this.cookieService.get('rodoModalClosed')) : false;
		
		if (!rodoModalClosed) {
			setTimeout(() => {
				let modalOption: NgbModalOptions = {};
				modalOption.backdrop = 'static';
				modalOption.keyboard = false;
				
				this.modalService.open(RodoModalComponent, modalOption);
			});
		}
	}
	
	private getRegulations() {
		this.miscService.fetchRegulations(this.year).then(data => {
			this.regulations = data.regulations;
			
			if (this.regulations && this.regulations.length) {
				this.mainRegulations = this.regulations.slice(0, 2);
				
				this.discRegulations = this.regulations.slice(3);
				this.discRegulations.sort((a, b) => {
					if(a.title < b.title) { return -1; }
					if(a.title > b.title) { return 1; }
					return 0;
				})
				
				this.discRegulationsSwitcher = this.regulations[2];
			} else {
				this.mainRegulations = [];
				this.discRegulations = [];
				this.discRegulationsSwitcher = null;
			}
		})
	}
	
	public switchDiscRegulations() {
		this.showDiscRegulations = !this.showDiscRegulations;
	}
}
