import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

// components
import { PagerNavbarComponent } from './pager-navbar/pager-navbar.component';
import { HumsolTableComponent } from './humsol-table/humsol-table.component';
import { TypeheadSelectClubComponent } from './typehead-select-club/typehead-select-club.component';
import { TypeheadSelectPlayerComponent } from './typehead-select-player/typehead-select-player.component';
import { TypeheadSelectCoachComponent } from './typehead-select-coach/typehead-select-coach.component';
import { AddNewClubComponent } from './add-new-club/add-new-club.component';
import { TinymceComponent } from './tinymce/tinymce.component';

// filters
import { FilterPipe } from './filters/filter.pipe';
import { LineBreakPipe } from './filters/lineBreak.pipe';
import { OrderByPipe } from './filters/orderBy.pipe';
import { MiejsceToTextPipe } from './filters/miejsceToText.pipe';

// services
import { StatisticsService } from 'shared/services/statistics.service';
import { MiscService } from 'shared/services/misc.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    EditorModule
  ],
  exports: [
    PagerNavbarComponent,
    HumsolTableComponent,
    TypeheadSelectClubComponent,
    TypeheadSelectPlayerComponent,
    TypeheadSelectCoachComponent,
    AddNewClubComponent,
    TinymceComponent,
    FilterPipe,
    LineBreakPipe,
    OrderByPipe,
    MiejsceToTextPipe
  ],
  declarations: [
    PagerNavbarComponent,
    HumsolTableComponent,
    TypeheadSelectClubComponent,
    TypeheadSelectPlayerComponent,
    TypeheadSelectCoachComponent,
    AddNewClubComponent,
    FilterPipe,
    LineBreakPipe,
    OrderByPipe,
    MiejsceToTextPipe,
    TinymceComponent
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    StatisticsService,
    MiscService
  ]
})
export class UtilsModule { }
