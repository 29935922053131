<div class="container">
    <div class="row justify-content-center" style="height: calc(100vh - 140px)">
        <div class="col-md-6 align-self-center">
            <div class="card" *ngIf="mode === 'logowanie'">
                <div class="card-header">
                    <span>Logowanie</span>
                </div>
                <div class="card-body">
                    <form>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" aria-describedby="email" name="email" [(ngModel)]="email" placeholder="email">
                        </div>
                        <div class="form-group">
                            <label for="password">Hasło</label>
                            <input id="password" type="password" class="form-control" name="hasło" [(ngModel)]="password" placeholder="hasło">
                        </div>
                        <div class="alertMessage" *ngIf="message" style="margin-bottom: 15px;">{{message}}</div>
                        
                        <div class="row">
                            <div class="col"><button type="submit" class="btn btn-primary" (click)="logIn()">Login</button></div>
                            <div class="col text-right"><a class="btn btn-link" routerLink="/resetowanie-hasla">Resetuj hasło</a></div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card" *ngIf="mode === 'resetowanie-hasla'">
                <div class="card-header">
                    <span>Resetowanie hasła</span>
                </div>
                <div class="card-body">
                    <form>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" aria-describedby="email" name="email" [(ngModel)]="email" placeholder="email">
                        </div>
                        <div class="alertMessage" *ngIf="message" style="margin-bottom: 15px;">{{message}}</div>
                        
                        <div class="row">
                            <div class="col"><button type="submit" class="btn btn-primary" (click)="resetPassword()">Resetuj</button></div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card" *ngIf="mode === 'aktywacja-konta' || mode === 'nowe-haslo'">
                <div class="card-header">
                    <span *ngIf="mode === 'aktywacja-konta'">Aktywacja konta</span>
                    <span *ngIf="mode === 'nowe-haslo'">Nowe hasło</span>
                </div>
                <div class="card-body">
                    <form>
                        <div class="form-group">
                            <label for="password1">Hasło</label>
                            <input id="password1" type="password" class="form-control" [ngClass]="{'border border-danger': alerts?.newPassword1}" name="hasło" [(ngModel)]="newPassword1" placeholder="hasło">
                            <div class="alertMessage" *ngIf="alerts?.newPassword1">{{alerts?.newPassword1.msg}}</div>
                        </div>
                        
                        <div class="form-group">
                            <label for="password2">Potwierdź hasło</label>
                            <input id="password2" type="password" class="form-control" [ngClass]="{'border border-danger': alerts?.newPassword2}" name="hasło" [(ngModel)]="newPassword2" placeholder="hasło">
                            <div class="alertMessage" *ngIf="alerts?.newPassword2">{{alerts?.newPassword2.msg}}</div>
                        </div>
                        
                        <div class="alertMessage" *ngIf="message">{{message}}<br></div>
                        
                        <div class="row">
                            <div class="col" *ngIf="mode === 'aktywacja-konta'"><button type="submit" class="btn btn-primary" (click)="activateAccount()">Aktywuj konto</button></div>
                            <div class="col" *ngIf="mode === 'nowe-haslo'"><button type="submit" class="btn btn-primary" (click)="activateAccount()">Potwierdź</button></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>