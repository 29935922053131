import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { DynamicContent } from 'shared/model/dynamicContent';
import { MiscService } from 'shared/services/misc.service';

@Component({
	templateUrl: './dynamic-content-main.component.html'
})
export class DynamicContentMainComponent implements OnInit, OnDestroy {
	constructor(private router: Router, private route: ActivatedRoute, private miscService: MiscService) {
		this.routeSubscription = router.events.subscribe(e => {
			if (e instanceof NavigationEnd && this.currentUrl !== e.url && this.dynamicContent)
				this.ngOnInit();
		})
	}
	
	private routeSubscription: Subscription;
	private currentUrl: string = '';
	
	public dynamicContent: DynamicContent;
	
	ngOnInit() {
		let currentUrl: string = this.route.snapshot.params['dynamicContentName'];
		this.miscService.fetchDynamicContent(currentUrl, false).then(data => {
			this.dynamicContent = data.dynamicContent;
		})
	}
	
	public ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
    }
}
