<div class="row top-header pt-2" *ngIf="statystykiDymki">

	<div class="col-lg-2 col-md-3">
		<div class="text-center">
			<a routerLink="/">
				<img src="angular/assets/images/zsmlogo_noBG.png" alt="" style="width: 40%;">
				<div class="logo-sidebar">
					Zespół Metodyczny
					<br>
					<small>Instytut Sportu w Warszawie</small>
					<br>
					<br>
				</div>
			</a>
		</div>
	</div>
	<div class="col-md-9 col-lg-10 px-4">
		<div class="clearfix">
			<h5 class="float-left">
				<span>Statystyki za</span>

			</h5>
			<div class="dropdown float-left">
				<div ngbDropdown class="d-inline-block">
					<button style="margin-top: -9px;" class="btn btn-link" id="statYearDropdown" ngbDropdownToggle>{{filterStatYear}}</button>
					<div ngbDropdownMenu aria-labelledby="statYearDropdown">
						<button class="dropdown-item" *ngFor="let statYear of statYears" [value]="statYear" (click)="onSelectStatYear($event.target.value)">{{statYear}}</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row row-padding">
			<div class="col-xl col-3 mb-2 p-1">
				<div class="card card-tile card-xs bg-secondary bg-gradient text-center">
					<div class="card-body p-2">
						<a routerLink="statystyki-dyscyplin">
							<div class="tile-right">
								<div class="tile-description">PULA PUNKTÓW<br>DO ZDOBYCIA</div>
								<div class="tile-number"> {{statystykiDymki.punktyDoZdobycia}}</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="col-xl col-3 mb-2 p-1">
				<div class="card card-tile card-xs bg-secondary bg-gradient text-center">
					<div class="card-body p-2">
						<a routerLink="statystyki-dyscyplin">
							<div class="tile-right">
								<div class="tile-description">PUNKTY<br>JUŻ ZALICZONE</div>
								<div class="tile-number">{{statystykiDymki.punktyZaliczone}}</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="col-xl col-3 mb-2 p-1">
				<div class="card card-tile card-xs bg-secondary bg-gradient text-center">
					<div class="card-body p-2">
						<a routerLink="wykaz-wydarzen">
							<div class="tile-right">
								<div class="tile-description">LICZBA<br>WYDARZEŃ</div>
								<div class="tile-number">{{statystykiDymki.liczbaWydarzen}}</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="col-xl col-3 mb-2 p-1">
				<div class="card card-tile card-xs bg-secondary bg-gradient text-center">
					<div class="card-body p-2">
						<a routerLink="wykaz-zawodnikow">
							<div class="tile-right">
								<div class="tile-description">LICZBA<br>ZAWODNIKÓW</div>
								<div class="tile-number">{{statystykiDymki.liczbaZawodnikow}}</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="col-xl col-3 mb-2 p-1">
				<div class="card card-tile card-xs bg-secondary bg-gradient text-center">
					<div class="card-body p-2">
						<a routerLink="klasyfikacja-klubow">
							<div class="tile-right">
								<div class="tile-description">LICZBA<br>KLUBÓW</div>
								<div class="tile-number">{{statystykiDymki.liczbaKlubow}}</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="col-xl col-3 mb-2 p-1">
				<div class="card card-tile card-xs bg-secondary bg-gradient text-center">
					<div class="card-body p-2">
						<a routerLink="klasyfikacja-wojewodztw">
							<div class="tile-right">
								<div class="tile-description">LICZBA<br>WOJEWÓDZTW</div>
								<div class="tile-number">{{statystykiDymki.liczbaWojewodztw}}</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="col-xl col-3 mb-2 p-1">
				<div class="card card-tile card-xs bg-secondary bg-gradient text-center">
					<div class="card-body p-2">
						<a routerLink="klasyfikacja-powiatow">
							<div class="tile-right">
								<div class="tile-description">LICZBA<br>POWIATÓW</div>
								<div class="tile-number">{{statystykiDymki.liczbaPowiatow}}</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="col-xl col-3 mb-2 p-1">
				<div class="card card-tile card-xs bg-secondary bg-gradient text-center">
					<div class="card-body p-2">
						<a routerLink="klasyfikacja-gmin">
							<div class="tile-right">
								<div class="tile-description">LICZBA<br>GMIN</div>
								<div class="tile-number">{{statystykiDymki.liczbaGmin}}</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>