export class Paging {
	public totalNumberOfRows: number;
	public currentPage: number;
	public numOfAdditionalNumbers: number;
	public pagerNumRange: number[];
	public lastPage: number;
	public needCount: boolean;
	public pageSize: number;

	constructor(needCount: boolean, pageSize: number, numOfAdditionalNumbers: number) {
		this.currentPage = 1;
		this.needCount = needCount;
		this.pageSize = pageSize;
		this.numOfAdditionalNumbers = numOfAdditionalNumbers;
	}

	public calculateLastPage() {
		this.lastPage = Math.ceil(this.totalNumberOfRows / this.pageSize);
	}

	public setNumbersRange() {
		this.pagerNumRange = [];
		for (var i = this.currentPage - this.numOfAdditionalNumbers; i <= this.currentPage + this.numOfAdditionalNumbers; i++) {
			if (i > 0 && i <= this.lastPage)
				this.pagerNumRange.push(i);
		}
	}
}