import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Alerts } from 'shared/model/alerts';

import { SessionService } from 'shared/services/session.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public mode: string;
    constructor(private sessionService: SessionService, private route: ActivatedRoute, private router: Router) {
        this.mode = this.route.snapshot.url[0].path;
		this.passResetToken = this.route.snapshot.params['passResetToken'];
    }
    
    public message: string = '';
    public alerts: Alerts;

    public email: string;
    public password: string;

	private passResetToken: string;
    public newPassword1: string;
	public newPassword2: string;

    public passReseting: boolean;

    public ngOnInit() {
    }

    public logIn() {
        this.sessionService.logIn(this.email, this.password).then(data => {
            if (data.status.code !== 0) {
                this.message = data.status.msg;
            }
        });
    }

    public resetPassword() {
        this.sessionService.resetPassword(this.email).then(data => {
            if (data.status.code === 0) {
				this.message = 'Na podany adres email została wysłana wiadomość z linkiem resetującym hasło.';
            } else {
				this.message = data.status.msg;
				
				if (data.alerts) {
					this.alerts = new Alerts();
					for (let alert of data.alerts.alerts) {
						this.alerts[alert.key] = alert;
					}
				}
			}
        });
    }
    
    public activateAccount() {
        this.sessionService.activateAccount(this.passResetToken, this.newPassword1, this.newPassword2).then(data => {
            if (data.status.code === 0) {
				
				this.router.navigate(['/logowanie']);
            } else {
				this.message = data.status.msg;
				
				if (data.alerts) {
					this.alerts = new Alerts();
					for (let alert of data.alerts.alerts) {
						this.alerts[alert.key] = alert;
					}
				}
			}
        });
	}
}
