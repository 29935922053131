import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from 'shared/model/user';
import { Rights } from 'shared/model/rights';
import { SessionData } from 'shared/model/sessionData';


@Injectable({
	providedIn: 'root'
})
export class SessionService {
    constructor(private http: Http, private router: Router) {
        this.updateSessionData();
    }

	private sessionData = new BehaviorSubject<SessionData>(new SessionData());
    
    private loggedUser: User;
    private rights: Rights;

    public watchSessionData(): Observable<SessionData> {
        return this.sessionData.asObservable();
    }

    public async updateSessionData() {
        this.sessionData.next({
            user: await this.getUser(),
            rights: await this.getRights()
        })
    }

    private userChecked: boolean = false;

    public async getUser(): Promise<User> {
        if (!this.userChecked)
            await this.fetchLoggedUser();

        return this.loggedUser;
    }

    public async getRights(): Promise<Rights> {
        if (!this.userChecked)
            await this.fetchLoggedUser();

        return this.rights;
    }

    public fetchLoggedUser(): Promise<SessionData> {
        return this.http.post('/ws/system/getuser', JSON.stringify({}))
        .pipe(map((response: Response) => response.json()), tap(data => {
                this.loggedUser = data.user;
                if (this.loggedUser) {
                    this.rights = new Rights();
                    this.rights.setRights(this.loggedUser);
                    this.userChecked = true;
                } else {
                    this.rights = undefined;
                }
            })
        )
        .toPromise();
    }
    
    public logIn(userEmail: string, userPass: string): any {
        return this.http.post('/ws/system/login', JSON.stringify({
            email: userEmail,
            pass: userPass
        }))
        .pipe(map((response: Response) => response.json()), tap(data => {
            if (data.status.code === 0) {
                this.userChecked = false;
                this.updateSessionData();
				
                this.router.navigate(['admin']);
            }
        }))
        .toPromise();
    }

    public logOut(): any {
        return this.http.post('/ws/system/logout', JSON.stringify({ }))
        .pipe(tap(data => {
            this.userChecked = false;
            this.updateSessionData();

            this.router.navigate(['']);
        }))
        .toPromise();
    }
    
    public resetPassword(email: string): any {
        return this.http.post('/ws/system/recover', JSON.stringify({
            email: email,
        }))
        .pipe(map((response: Response) => response.json()))
		.toPromise();
    }

    public activateAccount(passResetToken: string, newPassword1: string, newPassword2: string): any {
        return this.http.post('/ws/system/resetPassword', JSON.stringify({
            passResetToken: passResetToken,
            newPassword1: newPassword1,
            newPassword2: newPassword2
        }))
        .pipe(map((response: Response) => response.json()))
		.toPromise();
    }
}
