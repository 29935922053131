import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { Paging } from '../../utils/paging';

@Component({
	selector: 'utils-pager-navbar',
	templateUrl: './pager-navbar.component.html',
	styleUrls: ['./pager-navbar.component.scss']
})
export class PagerNavbarComponent implements OnInit {
	@Input() disabled: boolean;
	@Input() paging: Paging;
	@Output() changePage: EventEmitter<Paging> = new EventEmitter<Paging>();
	
	constructor() {}
	
	onChangePage(direction: string) {
		if (direction === 'prev') this.paging.currentPage--;
		if (direction === 'next') this.paging.currentPage++;
		if (direction === 'first') this.paging.currentPage = 1;
		if (direction === 'last') this.paging.currentPage = Math.ceil(this.paging.totalNumberOfRows / this.paging.pageSize);
		if (/[0-9]/.test(direction)) this.paging.currentPage = Number(direction);
		
		this.paging.setNumbersRange();
		
		this.changePage.emit(this.paging);
	}

	ngOnInit() {}
}
