import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PlayerClubChangeApplication } from 'shared/model/playerClubChangeApplication';
import { Paging } from 'shared/utils/paging';

import { SessionService } from 'shared/services/session.service';
import { MiscService } from 'shared/services/misc.service';

@Component({
	selector: 'wnioski-podzial-punktow-miedzy-klubami',
	templateUrl: './wnioski-podzial-punktow-miedzy-klubami.component.html'
})
export class WnioskiPodzialPunktowMiedzyKlubamiComponent implements OnInit {
	constructor(private router: Router, private route: ActivatedRoute, private miscService: MiscService) {}
	
	public imageNumber: number = 0;
	
	public applications: PlayerClubChangeApplication[] = [];
	
	public statYears: number[];
	public disciplines: any[];
	
	public totalNumberOfRows: number;
	public paging = new Paging(true, 20, 5);
	public sort: string = '';
	
	public filterYear: number;
	public filterDisciplineId: number = 0;
	public filterLastName: string = '';
	public filterStatus: string = '';
	
	public onChangeQueryFilter(filterName: string) {
		if (filterName === 'disciplineId') {
			let discipline = this.disciplines.find(discipline => discipline.id === this.filterDisciplineId);
			let disciplineUrl = discipline ? discipline.url : null;
			this.router.navigate(
				['/wnioski/podzial-punktow-miedzy-klubami'],
				{ queryParams: { dyscyplina: disciplineUrl } }
			);
		}
		
		this.paging.currentPage = 1;
		this.loadTableData();
	}
	
	public onChangeOrderFilter(filterName: string) {
		if (filterName !== '') {
			this.sort = this.miscService.changeTableSort(filterName, this.sort);
			this.loadTableData();
		}
	}
	
	onChangePage(event_paging: Paging) {
		this.paging = event_paging;
		this.loadTableData();
	}
	
	ngOnInit() {
		let disciplineFromRoute: string = null;
		this.route.queryParams.subscribe(params => {
			disciplineFromRoute = params.dyscyplina;
		});
		
		this.imageNumber = this.miscService.getRandomNumber(0, 33);
		
		this.miscService.fetchStatYears().then(data => this.statYears = data.statYears);
		this.filterYear = this.miscService.getCurrentYear();
		this.miscService.fetchDisciplines().then(data => {
			this.disciplines = data.disciplines;
			if (disciplineFromRoute) {
				let discipline = this.disciplines.find(discipline => discipline.url === disciplineFromRoute);
				if (discipline)
					this.filterDisciplineId = discipline.id;
				else
					this.router.navigate(['/wnioski/podzial-punktow-miedzy-klubami']);
			}
		});
		
		if (disciplineFromRoute) {
			let interval = setInterval(() => {
				if (this.filterDisciplineId) {
					this.loadTableData();
					clearInterval(interval);
				}
			}, 200);
		} else {
			this.loadTableData();
		}
	}
	
	public loadTableData() {
		this.miscService.fetchPlayerClubChangeApplications(this.paging, this.filterYear, this.filterDisciplineId, this.filterLastName, this.filterStatus, this.sort).then(data => {
			if (data && data.applications) {
				this.applications = data.applications;
				this.totalNumberOfRows = data.totalNumberOfRows;
				
				this.calculateLp();
			}
		});
	}
	
	private calculateLp() {
		this.paging.totalNumberOfRows = this.totalNumberOfRows;
		this.paging.calculateLastPage();
		this.paging.setNumbersRange();
		
		let startNumber = 1;
		for (let app of this.applications) {
			app.lp = (this.paging.currentPage - 1) * this.paging.pageSize + startNumber;
			startNumber++;
			
			app.editing = false;
		}
	}
}
