<div class="home">
	<div class="fix-width">
		<div class="row banner-text">
			<div class="col-lg-6 m-t-20">
				<h1>
					<span class="text-info">Ranking</span><br>Systemu Sportu Młodzieżowego</h1>
				<p class="subtext">
					<span class="font-medium">
						Spójny i jednolity dla wszystkich dyscyplin system klasyfikacji polskiego sportu młodzieżowego. Budowany corocznie.
						Uwzględnia wyniki z głównych imprez (zwykle Mistrzostwa Polski) dla 4 grup wiekowych: młodzik, junior młodszy, junior, młodzieżowiec.
						Udostępnione wyniki dają możliwość oceny i porównania osiągnięć sportowych w rywalizacji klubów, województw, powiatów i gmin.
					</span>
				</p>
				
				<div class="row mt-4" *ngIf="dynamicContents">
					<div *ngFor="let dc of dynamicContents" class="col-md-6 col-lg-6 col-xl-3 mb-2">
						<div class="card card-tile card-xs bg-green bg-gradient text-center">
							<div class="card-body" style="padding:6px 10px 6px 6px;">
								<a [routerLink]="['inne', dc.url]">
									<div class="tile-right">
										<div class="tile-description">{{ dc.name }}</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div [ngStyle]="{ 'background-image': 'url(angular/assets/images/discs-images-with-logos/' + imageNumber + '.jpg)' }"
					 style="background-position: center center; height: 265px; background-size: cover; border-radius: 0.4167rem; margin-bottom: 10px;"></div>
				<!-- <div style="background-image: url(assets/images/temp-photo.JPG); background-position: center center; height: 300px; background-size: cover; border-radius: 0.4167rem;"></div> -->
			</div>
		</div>
	</div>
</div>

<div class="row mt-4" *ngIf="statystykiDymki">
	<div class="col-md-6 col-lg-6 col-xl-3 mb-2">
		<div class="card card-tile card-xs bg-secondary bg-gradient text-center">
			<div class="card-body p-4">
				<a routerLink="/klasyfikacja-klubow">
					<div class="tile-left">
						<i class="material-icons">show_chart</i>
					</div>
					<div class="tile-right">
						<div class="tile-number">{{statystykiDymki.liczbaKlubow}}</div>
						<div class="tile-description">Klubów</div>
					</div>
				</a>
			</div>
		</div>
	</div>
	<div class="col-md-6 col-lg-6 col-xl-3 mb-2">
		<div class="card card-tile card-xs bg-primary bg-gradient text-center">
			<div class="card-body p-4">

				<a routerLink="/wykaz-zawodnikow">
					<div class="tile-left">
						<i class="material-icons">people</i>
					</div>
					<div class="tile-right">
						<div class="tile-number">{{statystykiDymki.liczbaZawodnikow}}</div>
						<div class="tile-description">Zawodników</div>
					</div>
				</a>
			</div>
		</div>
	</div>
	<div class="col-md-6 col-lg-6 col-xl-3 mb-2">
		<div class="card card-tile card-xs bg-secondary bg-gradient text-center">
			<div class="card-body p-4">
				<a routerLink="/wykaz-wydarzen">
					<div class="tile-left">
						<i class="material-icons">event</i>
					</div>
					<div class="tile-right">
						<div class="tile-number">{{statystykiDymki.liczbaWydarzen}}</div>
						<div class="tile-description">Wydarzeń</div>
					</div>
				</a>
			</div>
		</div>
	</div>
	<div class="col-md-6 col-lg-6 col-xl-3 mb-2">
		<div class="card card-tile card-xs bg-primary bg-gradient text-left">
			<div>
				<div class="card-body p-4">
					<div class="tile-left"><i class="material-icons">bar_chart</i></div>
					<div class="tile-right">
						<div>
							<a routerLink="/klasyfikacja-wojewodztw">
								<span class="tile-right">
									<span class="tile-description">Województw: <strong>{{statystykiDymki.liczbaWojewodztw}}</strong></span>
								</span>
							</a>
						</div>
						<a routerLink="/klasyfikacja-powiatow">
							<span class="tile-right">
								<span class="tile-description">Powiatów: <strong>{{statystykiDymki.liczbaPowiatow}}</strong></span>
							</span>
						</a>
						<div>
							<a routerLink="/klasyfikacja-gmin">
								<span class="tile-right">
									<span class="tile-description">Gmin: <strong>{{statystykiDymki.liczbaGmin}}</strong> </span>
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="row row-eq-height mt-3 mb-2">
	<div class="col-md-8">
		<div class="card h-100">
			<div class="card-header">
				<h5 class="card-title float-left"><i class="material-icons">bar_chart</i> Ostatnio dodane wyniki:</h5>
			</div>
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-sm table-striped">
						<thead>
							<tr class="lighter">
								<th>WYDARZENIA</th>
								<th>Dyscyplina</th>
								<th>Grupa wiekowa</th>
								<th>Liczba klubów</th>
								<th>Liczba zawodnikow</th>
								<th>Liczba punktów</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let wynik of ostatnieWyniki">
								<td><a routerLink="/wydarzenie/{{wynik.eventId}}/{{wynik.eventUrl}}">{{wynik.eventName}}</a></td>
								<td><a routerLink="/wykaz-wydarzen-w-dyscyplinie/{{wynik.disciplineId}}/{{wynik.disciplineUrl}}">{{wynik.disciplineName}}</a></td>
								<td>{{wynik.grupaWiekowa}}</td>
								<td>{{wynik.liczbaKlubow}}</td>
								<td>{{wynik.liczbaZawodnikow}}</td>
								<td>{{wynik.liczbaPunktow}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<div class="col-md-4">
		<div class="card h-100">
			<div class="card-header">
				<h5 class="card-title">
					<div class="row">
						<div class="col">
							<i class="material-icons">info</i> Regulaminy
						</div>
						<div class="col">
							<div class="input-group-prepend">
								<div class="input-group-prepend">
									<label class="input-group-text" for="selectYear">Rok:</label>
								</div>
								<select id="selectYear" class="custom-select form-control-sm" [(ngModel)]="year" (change)="onChangeYear()">
									<option *ngFor="let year of years" [ngValue]="year.year">{{ year.year }}</option>
								</select>
							</div>
						</div>
					</div>
				</h5>
			</div>
			<div *ngIf="regulations && regulations.length" class="card-body">
				<div>
					<ul class="news">
						<li *ngFor="let mReg of mainRegulations">
							<a [href]="'/file/' + mReg.fileId" target="_blank">{{ mReg.title }}</a>
						</li>
					</ul>
				</div>
				
				<br>
				
				<div style="margin: 0 6px;">
					<a href="javascript:void(0)" (click)="switchDiscRegulations()" style="text-decoration: none; color: black;">
						<i class="material-icons">{{ showDiscRegulations ? 'expand_less' : 'expand_more' }}</i>
						{{ discRegulationsSwitcher.title }}
					</a>
					
					<ul *ngIf="showDiscRegulations" class="news">
						<li *ngFor="let dReg of discRegulations" style="margin-left: 15px;">
							<a *ngIf="dReg.fileId" [href]="'/file/' + dReg.fileId" target="_blank">{{ dReg.title }}</a>
							<div *ngIf="!dReg.fileId" class="row">
								<div class="col-sm-10">{{ dReg.title }}</div>
								<div class="col-sm-2 text-right"><i class="material-icons">file_download_off</i></div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div *ngIf="regulations && !regulations.length" class="card-body text-center">
				<h6>Brak regulaminów na wybrany rok.</h6>
			</div>
		</div>
	</div>
</div>